<template>
  <div>
    <section>
      <div class="box p-2 mt-2">
        <div
          class="boxed-tabs nav nav-tabs text-xs justify-center w-full bg-gray-200 text-gray-600 dark:bg-dark-1 dark:text-gray-500 rounded-md mx-auto flex-col sm:flex-row"
          role="tablist"
        >
          <router-link
            id="tutela-tab"
            :to="{ name: 'pharmasan.administrativa.juridica.tutelas.ver.tutela' }"
            :class="getActive([
              'pharmasan.administrativa.juridica.tutelas.ver.tutela'
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <TabletIcon class="w-4 h-4 mr-2"/>
            Tutela
          </router-link>
          <router-link
            id="fallos-tab"
            :to="{ name: 'pharmasan.administrativa.juridica.tutelas.ver.fallos' }"
            :class="getActive([
              'pharmasan.administrativa.juridica.tutelas.ver.fallos',
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <ShoppingBagIcon class="w-4 h-4 mr-2"/>
            Fallos
          </router-link>
          <router-link
            id="incidentes-tab"
            :to="{ name: 'pharmasan.administrativa.juridica.tutelas.ver.incidentes' }"
            :class="getActive([
              'pharmasan.administrativa.juridica.tutelas.ver.incidentes'
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <ShoppingBagIcon class="w-4 h-4 mr-2"/>
            Incidentes
          </router-link>
          <router-link
            id="desacatos-tab"
            :to="{ name: 'pharmasan.administrativa.juridica.tutelas.ver.desacatos' }"
            :class="getActive([
              'pharmasan.administrativa.juridica.tutelas.ver.desacatos'
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <BoxIcon class="w-4 h-4 mr-2"/>
            Sanciones
          </router-link>
          <router-link
            id="solicitudes-tab"
            :to="{ name: 'pharmasan.administrativa.juridica.tutelas.ver.solicitudes' }"
            :class="getActive([
              'pharmasan.administrativa.juridica.tutelas.ver.solicitudes'
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <BarChartIcon class="w-4 h-4 mr-2"/>
            Solicitudes
          </router-link>
        </div>
      </div>
    </section>
    <router-view/>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'

export default {
  name: 'VerMain',
  setup () {
    const route = useRoute()

    const getActive = (routeNames = []) => {
      if (routeNames.includes(route.name)) {
        return 'active'
      }
      return ''
    }

    return {
      getActive
    }
  }
}
</script>

<style scoped>

</style>
